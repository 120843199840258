import "./components/css/common.css"
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from "./components/utils/AuthProvider";
import Forbidden from "./components/Forbidden";
import Home from "./components/Home";
import ServiceModeView from "./components/ServiceModeView";
import LoadChamberView from "./components/LoadChamberView";
import PrivateRoute from "./components/utils/PrivateRoute";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path={process.env.REACT_APP_ROUTE_HOME} element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path={process.env.REACT_APP_ROUTE_SERVICE_MODE_VIEW} element={<PrivateRoute><ServiceModeView /></PrivateRoute>} />
            <Route path={process.env.REACT_APP_ROUTE_LOAD_CHAMBER_VIEW} element={<PrivateRoute><LoadChamberView /></PrivateRoute>} />
            <Route path={process.env.REACT_APP_ROUTE_FORBIDDEN} element={<Forbidden />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;