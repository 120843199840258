import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const PrivateRoute = ({ children }) => {
    const { isAuthenticated, handleAuth } = useAuth();
    handleAuth();

    if (isAuthenticated === null) {
        return null;
    }
    return isAuthenticated ? children : <Navigate to={process.env.REACT_APP_ROUTE_FORBIDDEN} replace={true} />;
};

export default PrivateRoute;